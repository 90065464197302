<template>
  <div class="checkbox-container">
    <label :for="id || 'checkbox'">
      <input
        type="checkbox"
        v-bind:checked="checked"
        v-on:change="$emit('change', $event.target.checked)"
        :id="id || 'checkbox'"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: ["label", "checked", "id"],
  model: {
    prop: "checked",
    event: "change",
  },
};
</script>

<style lang="scss">
/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 12px;
  height: 28px;
  width: 28px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    border: 1px solid #8e9c9c;
    background-color: unset;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 4px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #CA6D4F;
    }
  }
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

</style>
