<template>
  <div class="wrapper">
    <div class="payment-page">
      <div class="wrapper-payment">
        <div class="suit-payment" v-if="step === 1">
          <button class="btn-go-back" @click="goBack()">
            <v-icon color="#304251" size="22">mdi-chevron-left</v-icon>
            <span>GO BACK</span>
          </button>
          <p class="suit-payment-title">Pay for Your Suit</p>
          <div class="suit-info" v-if="suitCombo && suitMasterData">
            <div class="left-side">
              <div class="selected-suit">
                <p class="suit-info-title">Selected Suit:</p>
                <span>
                  {{ fullSuit.suit.name }} Suit ({{ listCurrency[formData.currency].symbol + (fullSuit.suit.price *
                      totalExchanged.exchange_rate).toFixed(2)
                  }})
                </span>
                <span v-if="fullSuit.shirt.price != 0">
                  {{ fullSuit.shirt.name }} Shirt ({{ listCurrency[formData.currency].symbol + (fullSuit.shirt.price *
                      totalExchanged.exchange_rate).toFixed(2)
                  }})
                  <br /></span>
                <span v-if="fullSuit.tie.price != 0">
                  {{ fullSuit.tie.name }} ({{ listCurrency[formData.currency].symbol + (fullSuit.tie.price *
                      totalExchanged.exchange_rate).toFixed(2)
                  }})
                  <br />
                </span>
                <span v-if="fullSuit.tie.price != 0">
                  {{ fullSuit.color.name }} Tie <br /></span>
                <span v-if="fullSuit.suitPackage.price != 0">
                  {{ fullSuit.suitPackage.name }} Shoes, Socks & Belt ({{ listCurrency[formData.currency].symbol +
                      (fullSuit.suitPackage.price *
                        totalExchanged.exchange_rate).toFixed(2)
                  }})<br /></span>
              </div>
              <div class="cost">
                <div class="cost-row total-cost">
                  <b>Cost of suit:</b>
                  <p class="text">
                    {{ listCurrency[formData.currency].symbol + (formData.currency === listCurrency.USD.unit
                        ?
                        totalExchanged.total_price : totalExchanged.total_price_exchanged)
                    }}
                  </p>
                </div>
                <div v-if="shippingFee" class="cost-row shipping-fee">
                  <b>Shipping fee:</b>
                  <p class="text">
                    {{ listCurrency[formData.currency].symbol + (shippingFee).toFixed(2)
                    }}
                  </p>
                </div>
                <div class="cost-row discount" v-if="couponDetail.discount">
                  <b>Discount: <br /> (Suit only)</b>
                  <p class="text loading-discount" v-if="isLoadingDiscount">
                    ...
                  </p>
                  <p class="text" v-else>{{ couponDetail.discount }}</p>
                </div>
                <div class="cost-row total">
                  <b>Total:</b>
                  <p class="text" v-if="isLoadingDiscount">...</p>
                  <p class="text" v-else>{{ listCurrency[formData.currency].symbol + currentTotalExchanged.toFixed(2) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="right-side">
              <div class="full-suit-comp">
                <img class="full-suit-comp__base-image" :src="fullSuit.shirt.part" alt="shirt"
                  v-if="fullSuit.shirt && fullSuit.shirt.part" />
                <div class="tie-component" :style="{
                  'background-color': fullSuit.color.hex,
                  '-webkit-mask': `url(${fullSuit.tie.part})no-repeat center / contain`,
                }" v-if="fullSuit.tie && fullSuit.tie.part"></div>
                <img :src="fullSuit.suit.part" alt="suit" v-if="fullSuit.suit && fullSuit.suit.part" />
                <img src="../assets/img/suits/head.png" alt="head" />
                <img v-if="fullSuit.suitPackage && fullSuit.suitPackage.part" :src="fullSuit.suitPackage.part"
                  style="top: -2px" alt="head" />
              </div>
            </div>
          </div>
          <div v-else class="nodata">
            <p>No data to display</p>
          </div>
          <div class="card-info">
            <v-form ref="form" v-model="valid" lazy-validation class="card-form">
              <div class="info-row currency-payment-box">
                <div class="currency-cover">
                  <p class="info-title">Currency</p>
                  <Select @onChange="(c) => onChangeSelectedCurrency(c)" :rules="rules.required" :items="currencyItems"
                    :value="formData.currency" :hideDetail="true" />
                </div>
                <div class="payment-method-cover">
                  <p class="info-title hidden">Card type</p>
                  <div class="payment-method">
                    <img src="../assets/img/icon/payment_method.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="info-row coupon-code">
                <p class="info-title">Coupon Code</p>
                <div class="coupon-box">
                  <Input placeholder="Coupon Code" @onChange="
                    (v) => {
                      formData.coupon_code = v;
                      if (!v) {
                        errors.coupon_code = '';
                        couponDetail = {
                          couponCode: '',
                          couponName: '',
                          discount: '',
                          total: '',
                        };
                      }
                    }
                  " :value="formData.coupon_code" :hideDetail="true" :errorMessage="errors.coupon_code" @blur="(value) => {
  if (
    couponDetail.couponCode !== formData.coupon_code ||
    errors.coupon_code
  ) {
    applyCoupon(value);
  }
}
" />
                  <button class="apply-coupon-btn" @click="onClickApply($event)" :disabled="!formData.coupon_code">
                    Apply
                  </button>
                </div>
                <div class="discount">
                  <p v-if="isLoadingDiscount">...</p>
                  <p v-if="couponDetail.discount && !isLoadingDiscount">
                    {{ couponDetail.couponCode }}: {{ couponDetail.discount }}
                  </p>
                </div>
              </div>
              <div class="info-row card-number">
                <p class="info-title">Card Number</p>
                <div id="card-number-element" :class="[paymentIsRequired ? '' : ' disabled-input', 'field']"></div>
                <p class="error-message" id="cardNumberError"></p>
              </div>
              <div class="info-row expDate-Cvc">
                <div class="expDate">
                  <p class="info-title">Exp.Date</p>
                  <div id="card-expiry-element" :class="[
                    paymentIsRequired ? '' : ' disabled-input',
                    'field',
                  ]"></div>
                  <p class="error-message" id="cardExperyError"></p>
                </div>
                <div class="cvc">
                  <p class="info-title">
                    Security Code <span>(on back of card)</span>
                  </p>
                  <div id="card-cvc-element" :class="[
                    paymentIsRequired ? '' : ' disabled-input',
                    'field',
                  ]"></div>
                  <p class="error-message" id="cardCvcError"></p>
                </div>
              </div>
              <div class="info-row wedding-infomation">
                <p class="info-title">Wedding's information</p>
                <div class="email">
                  <Input placeholder="Email Address" :rules="rules.email" @onChange="(v) => (formData.email = v)"
                    :value="formData.email" :hideDetail="true" />
                  <Input placeholder="Confirm Email Address" :rules="rules.confirmEmail"
                    @onChange="(v) => (formData.confirm_email = v)" :value="formData.confirm_email"
                    :hideDetail="true" />
                </div>
                <div class="name">
                  <Input placeholder="Groom First Name" :rules="rules.required"
                    @onChange="(v) => (formData.first_name = v)" :value="formData.first_name" :hideDetail="true" />
                  <Input placeholder="Groom Last Name" :rules="rules.required"
                    @onChange="(v) => (formData.last_name = v)" :value="formData.last_name" :hideDetail="true" />
                </div>
                <div class="partyName-phoneNumber">
                  <Input placeholder="Wedding Party Name" :rules="rules.required"
                    @onChange="(v) => (formData.wedding_party_name = v)" :value="formData.wedding_party_name"
                    :hideDetail="true" />
                  <Input placeholder="Phone Number" :rules="rules.phoneNumber" @onChange="(v) => (formData.phone = v)"
                    :value="formData.phone" :hideDetail="true" type="number" />
                </div>
              </div>
              <div class="info-row shipping">
                <p class="info-title">Shipping Address</p>
                <div class="address-name">
                  <Input placeholder="First Name" :rules="rules.required"
                    @onChange="(v) => (formData.address_first_name = v)" :value="formData.address_first_name"
                    :hideDetail="true" />
                  <Input placeholder="Last Name" :rules="rules.required"
                    @onChange="(v) => (formData.address_last_name = v)" :value="formData.address_last_name"
                    :hideDetail="true" />
                </div>
                <div class="address-phone">
                  <Input placeholder="Phone Number" :rules="rules.phoneNumber"
                    @onChange="(v) => (formData.address_phone = v)" :value="formData.address_phone" :hideDetail="true"
                    type="number" />
                </div>
                <div class="address-line">
                  <Input placeholder="Address Line 1" :rules="rules.required"
                    @onChange="(v) => (formData.address_line1 = v)" :value="formData.address_line1" :hideDetail="true"
                    max="30" />
                </div>
                <div class="address-line">
                  <Input placeholder="Address Line 2" @onChange="(v) => (formData.address_line2 = v)"
                    :value="formData.address_line2" :hideDetail="true" max="30" />
                </div>
                <div class="address-info">
                  <Select @onChange="onSelectCountry" :rules="rules.required" :items="countries"
                    :value="formData.address_country" placeholder="Select Country" :hideDetail="true" />
                  <Input placeholder="City" :rules="rules.required" @onChange="(v) => (formData.address_city = v)"
                    :value="formData.address_city" :hideDetail="true" />
                </div>
                <div class="address-info">
                  <Select @onChange="onSelectState" :rules="rules.required" :items="
                    isCanada(formData.address_country)
                      ? [
                        { id: undefined, name: 'Select State' },
                        ...stateShipping,
                      ]
                      : stateShipping
                  " :isPlaceholder="true" :value="formData.address_state" placeholder="Select State"
                    :hideDetail="true" />
                  <Input placeholder="Zipcode" :rules="rules.zipcode" @onChange="(v) => (formData.address_zip = v)"
                    :value="formData.address_zip" :hideDetail="true" />
                </div>
                <p class="shipping-fee" v-if="shippingFee">
                  <b>
                    Shipping: {{ listCurrency[formData.currency].symbol + shippingFee.toFixed(2)
                    }}
                  </b>
                  <br />
                  to {{ selectedState ? selectedState.name : "" }} {{ selectedCountry ? selectedCountry.name : "" }}
                </p>
              </div>
              <div class="info-row billing-checkbox">
                <Checkbox v-model="activeBillingAddress" id="checkbox" />
                <label for="billing-checkbox" class="text"></label>
              </div>
              <v-list-group :value="activeBillingAddress" class="info-row billding-address-list">
                <template v-slot:activator />
                <v-list-item class="billing-address">
                  <p class="billing-title">Billing Address</p>
                  <div class="item name">
                    <Input placeholder="First Name" :rules="activeBillingAddress ? rules.required : undefined"
                      @onChange="(v) => (formData.billing_first_name = v)" :value="formData.billing_first_name"
                      :hideDetail="true" ref="billingFirstName" />
                    <Input placeholder="Last Name" :rules="activeBillingAddress ? rules.required : undefined"
                      @onChange="(v) => (formData.billing_last_name = v)" :value="formData.billing_last_name"
                      :hideDetail="true" ref="billingLastName" />
                  </div>
                  <div class="item address">
                    <Input placeholder="Address" :rules="activeBillingAddress ? rules.required : undefined"
                      @onChange="(v) => (formData.billing_address_line = v)" :value="formData.billing_address_line"
                      :hideDetail="true" ref="billingAddress" />
                  </div>
                  <div class="item country-city">
                    <Select @onChange="onSelectBillingAddressCountry"
                      :rules="activeBillingAddress ? rules.required : undefined" :items="countries"
                      :value="formData.billing_address_country" placeholder="Select Country" :hideDetail="true"
                      class="item" ref="billingCountry" />
                    <Input placeholder="City" :rules="activeBillingAddress ? rules.required : undefined"
                      @onChange="(v) => (formData.billing_address_city = v)" :value="formData.billing_address_city"
                      :hideDetail="true" class="item" ref="billingCity" />
                  </div>
                  <div class="item state-zipcode">
                    <Select @onChange="(v) => (formData.billing_address_state = v)" :rules="rules.required" :items="
                      isCanada(formData.billing_address_country)
                        ? [
                          { id: undefined, name: 'Select State' },
                          ...stateBilling,
                        ]
                        : stateBilling
                    " :isPlaceholder="true" :value="formData.billing_address_state" placeholder="Select State"
                      :hideDetail="true" ref="billingState" />
                    <Input placeholder="Zipcode" :rules="activeBillingAddress ? rules.billingAddressZipcode : undefined"
                      @onChange="(v) => (formData.billing_address_zip = v)" :value="formData.billing_address_zip"
                      :hideDetail="true" ref="billingZipcode" />
                  </div>
                </v-list-item>
              </v-list-group>

              <div class="info-shipping"></div>
              <button class="btn-complete" id="btnComplete">
                COMPLETE PURCHASE
              </button>
            </v-form>
          </div>
        </div>
        <div class="payment-confirmed" v-else>
          <p class="payment-confirmed-title">Order Confirmation</p>
          <p class="suit-status">
            Thank you for your payment.<br />You will be notified once your
            package has been shipped.
          </p>
          <hr />
          <p class="announcement">
            Please check your email for more information about joining our
            online portal!
          </p>
          <button class="btn-done" @click="done()">DONE</button>
        </div>
      </div>
    </div>

    <div v-if="loading" class="loading">
      <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import Input from "./ui/Input";
import Select from "./ui/Select";
import Checkbox from "./ui/Checkbox";

// mixin
import suitMixin from "../mixins/suitmixin";

//api
import { api } from "@goldfishcode/modern-groom-api-sdk";

// default suit
import ShirtPartImg from "../assets/img/shirts_and_ties/White.png";
import SuitPartImg from "../assets/img/suits/Black.png";
import { Currency } from "../constants";
export default {
  mixins: [suitMixin],

  data: function () {
    return {
      step: 1,
      userId: null,
      loading: true,
      isRenderedPaymentInput: false,
      listCurrency: Currency,
      DefaultSuit: {
        shirt: ShirtPartImg,
        suit: SuitPartImg,
      },
      valid: false,
      countries: [],
      stateShipping: [],
      stateBilling: [],
      activeBillingAddress: false,
      zipcodeRegex: {
        US: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
        CA: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
      },
      formData: {
        coupon_code: "",
        first_name: "",
        last_name: "",
        email: "",
        wedding_party_name: "",
        phone: "",
        confirm_email: "",
        address_first_name: "",
        address_last_name: "",
        address_phone: "",
        address_line1: "",
        address_line2: "",
        address_city: "",
        address_state: "",
        address_country: "",
        address_zip: "",
        token: "",
        currency: "USD",
        billing_first_name: "",
        billing_last_name: "",
        billing_address_country: "",
        billing_address_line: "",
        billing_address_city: "",
        billing_address_zip: "",
        billing_address_state: "",
      },
      rules: {
        required: [
          (v) => {
            if (v) {
              return !!v.trim();
            } else return "This field is required.";
          },
        ],
        confirmEmail: [
          (v) => !!v || "Confirm email is required.",
          (v) => v === this.formData.email || "Confirm email is invalid.",
        ],
        email: [
          (v) => !!v || "Email is required.",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid.",
        ],
        expDate: [
          (v) => !!v || "ExpDate is required.",
          (v) => {
            const data = v.split("/");
            if (data.length < 2) return true;
            if (parseInt(data[0]) > 12) {
              return "Month must be valid.";
            }
            const [month, year] = data;
            const currentYear = parseInt(
              new Date().getFullYear().toString().substr(-2)
            );
            const currentMonth = parseInt(new Date().getMonth().toString()) + 1;
            if (
              parseInt(year) < currentYear ||
              (parseInt(year) === currentYear && parseInt(month) < currentMonth)
            ) {
              return "ExpDate must be greater than the current";
            }
            return true;
          },
          (v) => /\d{2}\/\d{2}/.test(v) || "ExpDate must be MM/YY.",
        ],
        securityCode: [
          (v) => !!v || "Security code  is required.",
          (v) => /\d{4}/.test(v) || "Security code must be 4 digit.",
        ],
        zipcode: [
          (v) => !!v || "Zipcode is required.",
          (v) => {
            if (!this.formData.address_country) {
              return "Please select country."
            }
            if (this.countries.length < 0) {
              return false
            }
            const country = this.countries.find(country => country.id === this.formData.address_country)
            if (!country) {
              return false
            }

            if (this.isCanada(country.id)) {
              return this.zipcodeRegex.CA.test(v) || "Zipcode is invalid."
            } else {
              return this.zipcodeRegex.US.test(v) || "Zipcode is invalid."
            }
          }
        ],
        billingAddressZipcode: [
          (v) => !!v || "Zipcode is required.",
          (v) => {
            if (!this.formData.billing_address_country) {
              return "Please select country."
            }
            if (this.countries.length < 0 || !this.formData.billing_address_country) {
              return false
            }
            const country = this.countries.find(country => country.id === this.formData.billing_address_country)
            if (!country) {
              return false
            }

            if (this.isCanada(country.id)) {
              return this.zipcodeRegex.CA.test(v) || "Zipcode is invalid."
            } else {
              return this.zipcodeRegex.US.test(v) || "Zipcode is invalid."
            }
          }
        ],
        phoneNumber: [
          (v) => !!v || "Phone number  is required.",
          (v) => /^\d{9,11}$/.test(v) || "Phone number is invalid.",
        ],
        coupon_code: [],
      },
      errors: {
        coupon_code: "",
      },
      currencyItems: (() => {
        const result = [];
        for (const property in Currency) {
          result.push(Currency[property].unit);
        }
        return result;
      })(),
      currentTotalExchanged: 0,
      totalExchanged: {
        total_price: 0,
        total_price_exchanged: 0,
        exchange_rate: 0,
      },
      couponDetail: {
        discount: "",
        total: "",
        couponCode: "",
        couponName: "",
      },
      isLoadingDiscount: false,
      paymentIsRequired: true,
      stripeForm: {},
      listExtraShippingFee: [],
      shippingFee: 0,
      selectedState: null,
      selectedCountry: null,
      totalCost: 0,
    };
  },

  components: { Input, Select, Checkbox },

  methods: {
    isCanada(countrySelected) {
      if (!countrySelected) return true;
      const found = this.countries.find((f) => f.id === countrySelected);
      if (found && found.name.includes("Canada")) return true;
      return false;
    },
    changeExpDate(v) {
      this.formData.expDate = v.length === 2 ? v + "/" : v;
    },
    checkFormatNumber(event) {
      if (!/\d/.test(event.key)) {
        event.preventDefault();
      }
    },
    async onClickApply(event) {
      await this.applyCoupon(event);
      this.calculateShippingFee();
      this.calculateTotalCost();
    },

    onSelectCountry(v) {
      // manually trigger zipcode validation
      this.formData.address_country = v;
      this.selectedCountry = this.countries.find(country => country.id === v)
      const zipcode = this.formData.address_zip
      this.formData.address_zip = '';
      setTimeout(() => {
        this.formData.address_zip = zipcode;
      }, 0);
    },
    calculateShippingFee() {
      let shippingFee = this.listExtraShippingFee.find((item) => {
        if (item.state_code) {
          return item.country_code === this.selectedCountry?.iso_code && item.state_code === this.selectedState?.postal_code;
        }
        return item.country_code === this.selectedCountry?.iso_code;
      })?.fee;
      if (shippingFee) {
        if (this.formData.currency !== this.listCurrency['USD'].unit) {
          shippingFee *= this.totalExchanged?.exchange_rate;
        }
      }
      if (!shippingFee || !this.selectedState) {
        shippingFee = 0
      }
      this.shippingFee = shippingFee
    },
    onSelectState(v) {
      this.formData.address_state = v;
      this.selectedState = this.stateShipping.find(state => state.id === v);
      this.calculateShippingFee()
      this.calculateTotalCost()
    },
    onSelectBillingAddressCountry(v) {
      // manually trigger billing zipcode validation
      this.formData.billing_address_country = v;
      const zipcode = this.formData.billing_address_zip
      this.formData.billing_address_zip = '';
      setTimeout(() => {
        this.formData.billing_address_zip = zipcode;
      }, 0);
    },
    done() {
      window.location.href =
        "https://themoderngroom.com/build-your-suit-thank-you";
      localStorage.clear();
    },
    async checkUrlParams() {
      if (!this.userId) {
        await this.$router.push({
          path: "suit-fitted",
          query: { suitId: this.suitId },
        });
      }
      if (!this.suitId) {
        await this.$router.push({
          path: "suit",
          query: { userId: this.userId },
        });
      }
    },
    updatetieColor() {
      const paths = document.getElementsByTagName("path");
      if (!this.fullSuit.color || !paths || !paths[0]) {
        return;
      }
      paths[0].style.fill = this.fullSuit.color.hex;
    },
    goBack() {
      this.$router.push({
        path: "suit-fitted",
        query: { suitId: this.suitId, userId: this.userId },
      });
    },
    async onComplete() {
      try {
        const { confirm_email, coupon_code, ...rest } = this.formData;
        const params = {
          ...(!this.errors.coupon_code && this.couponDetail.couponCode
            ? { coupon_code: this.couponDetail.couponCode }
            : {}),
          suit_id: this.suitId,
          guest_id: this.userId,
          ...rest,
          currency: this.formData.currency.toLowerCase(),
          exchange_rate: this.totalExchanged?.exchange_rate,
        };
        if (!this.paymentIsRequired) {
          delete params.token;
        }
        if (!params.address_state) {
          delete params.address_state;
        }
        if (!this.activeBillingAddress) {
          delete params.billing_first_name;
          delete params.billing_last_name;
          delete params.billing_address_country;
          delete params.billing_address_line;
          delete params.billing_address_city;
          delete params.billing_address_zip;
          delete params.billing_address_state;
        } else {
          if (!params.billing_address_state) {
            delete params.billing_address_state;
          }
        }
        await api.User.registerGuest(params);
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "ecommerceInteraction",
            ecommerceInteraction: {
              property: "website",
              action: "purchase",
              value: this.currentTotalExchanged,
              currency: this.listCurrency[this.formData.currency].unit,
            },
          });
        }
        this.step = 2;
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    onChange(value) {
      this.fakeDropdownSelect = value;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    disablePaymentFlow(isDisabled) {
      this.paymentIsRequired = !isDisabled;
      let errorLineCardNumber = document.getElementById("cardNumberError");
      let errorLineExpery = document.getElementById("cardExperyError");
      let errorLineCarCvc = document.getElementById("cardCvcError");
      if (isDisabled) {
        this.stripeForm.cardExpiryElement.update({ disabled: true, value: "" }),
          this.stripeForm.cardCvcElement.update({ disabled: true, value: "" }),
          this.stripeForm.cardNumberElement.update({
            disabled: true,
            value: "",
          });
        this.stripeForm.cardNumberElement._parent.classList.remove(
          "fieldEmpty"
        );
        this.stripeForm.cardCvcElement._parent.classList.remove("fieldEmpty");
        this.stripeForm.cardExpiryElement._parent.classList.remove(
          "fieldEmpty"
        );
        errorLineCardNumber.textContent = "";
        errorLineExpery.textContent = "";
        errorLineCarCvc.textContent = "";
      } else {
        this.stripeForm.cardExpiryElement.update({ disabled: false }),
          this.stripeForm.cardCvcElement.update({ disabled: false }),
          this.stripeForm.cardNumberElement.update({ disabled: false });
      }
    },
    createStripeElm() {
      try {
        this.loading = true;
        var stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        var elements = stripe.elements({
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap",
            },
          ],
        });

        var style = {
          base: {
            color: "#000",
            lineHeight: "36px",
            fontSize: "14px",
            fontWeight: "400",
            marginTop: "1px",
            fontFamily: "Source Sans Pro",
            "::placeholder": {
              color: "rgba(0,0,0,0.6)",
            },
            ":disabled": {
              color: "#979494",
            },
          },
          invalid: {
            color: "red",
            iconColor: "blue",
            backgroundColor: "#fff",
          },
        };
        var cardNumberElement = elements.create("cardNumber", {
          style: style,
          placeholder: "Card Number",
        });
        cardNumberElement.mount("#card-number-element");

        var cardExpiryElement = elements.create("cardExpiry", {
          style: style,
          placeholder: "MM/YY",
        });
        cardExpiryElement.mount("#card-expiry-element");

        var cardCvcElement = elements.create("cardCvc", {
          style: style,
          placeholder: "Security Code",
        });
        cardCvcElement.mount("#card-cvc-element");
      } catch (error) {
        alert(error);
      }

      let errorLineCardNumber = document.getElementById("cardNumberError");
      cardNumberElement.on("change", function (event) {
        cardNumberElement._parent.classList.remove("fieldEmpty");
        if (event.error) {
          errorLineCardNumber.textContent = event.error.message;
        } else {
          errorLineCardNumber.textContent = "";
        }
      });
      cardNumberElement.on("blur", function (event) {
        if (
          cardNumberElement._parent.classList.contains("StripeElement--empty")
        ) {
          cardNumberElement._parent.classList.add("fieldEmpty");
          errorLineCardNumber.textContent = "Card Number is required";
        }
      });

      let errorLineCVC = document.getElementById("cardCvcError");
      cardCvcElement.on("change", function (event) {
        cardCvcElement._parent.classList.remove("fieldEmpty");
        if (event.error) {
          errorLineCVC.textContent = event.error.message;
        } else {
          errorLineCVC.textContent = "";
        }
      });
      cardCvcElement.on("blur", function (event) {
        if (cardCvcElement._parent.classList.contains("StripeElement--empty")) {
          cardCvcElement._parent.classList.add("fieldEmpty");
          errorLineCVC.textContent = "Security Code is required";
        }
      });

      let errorLineExpDate = document.getElementById("cardExperyError");
      cardExpiryElement.on("change", function (event) {
        cardExpiryElement._parent.classList.remove("fieldEmpty");
        if (event.error) {
          errorLineExpDate.textContent = event.error.message;
        } else {
          errorLineExpDate.textContent = "";
        }
      });
      cardExpiryElement.on("blur", function (event) {
        cardExpiryElement._parent.querySelectorAll("input")[0].checkValidity();
        if (
          cardExpiryElement._parent.classList.contains("StripeElement--empty")
        ) {
          cardExpiryElement._parent.classList.add("fieldEmpty");
          errorLineExpDate.textContent = "ExpDate is required";
        }
      });

      cardExpiryElement.on("ready", function () {
        el.isRenderedPaymentInput = true;
        el.loading = false;
        el.stripeForm = {
          cardExpiryElement,
          cardCvcElement,
          cardNumberElement,
        };
      });
      const btnComplete = document.getElementById("btnComplete");
      const el = this;
      btnComplete.addEventListener("click", async function (e) {
        e.preventDefault();
        el.loading = true;
        const isValid = el.$refs.form.validate();
        if (!isValid) {
          el.loading = false;
          return;
        }
        if (el.paymentIsRequired) {
          try {
            const res = await stripe.createToken(cardNumberElement);
            if (res.error) {
              el.loading = false;
              return;
            }
            if (!res.token.id) return;
            el.formData.token = res.token.id;
            el.onComplete();
          } catch (error) {
            el.loading = false;
          }
        } else {
          el.onComplete();
        }
      });
    },
    async getGuestInfo(userId) {
      try {
        const result = await api.User.viewGuest(userId);
        return result;
      } catch (error) {
        alert(error.message);
        return null;
      }
    },
    async getExtraShippingFee() {
      const res = await api.Suit.listExtraShippingFee({ page: 1, limit: 100 });

      if (res && res.results.length > 0) {
        this.listExtraShippingFee = res.results
      }
    },
    async getExchangeTotalPrice() {
      try {
        const exchangeRateParams = {
          total_price: this.fullSuit.price,
          currency: "CAD",
        };
        this.totalExchanged = await api.Suit.exchangeRate(exchangeRateParams);
        this.currentTotalExchanged = this.fullSuit.price;
      } catch (error) {
        alert(error?.message)
      }
    },
    calculateTotalCost() {
      if (this.formData.currency === this.listCurrency['USD'].unit) {
        this.currentTotalExchanged = this.totalExchanged.total_price;
      } else {
        this.currentTotalExchanged = this.totalExchanged.total_price_exchanged;
      }
      if (this.shippingFee) {
        this.currentTotalExchanged += this.shippingFee
      }
      if (this.couponDetail.discount) {
        this.currentTotalExchanged = this.couponDetail.total + this.shippingFee
      }
    },
    async onChangeSelectedCurrency(currency) {
      this.formData.currency = currency;
      if (this.formData.coupon_code) {
        await this.applyCoupon()
      }
      this.calculateShippingFee()
      this.calculateTotalCost()
    },
    async getCountry() {
      try {
        const result = await api.Location.getCountries();
        this.countries = result.results;
      } catch (error) {
        alert(error.message);
      }
    },
    async getState(countrySelected) {
      try {
        this.loading = true;
        const result = await api.Location.getStates(countrySelected);
        return result;
      } catch (error) {
        alert(error.message);
      } finally {
        this.loading = false;
      }
    },
    async applyCoupon(event) {
      if (event) {
        event.preventDefault();
      }
      try {
        this.isLoadingDiscount = true;
        const res = await api.Suit.applyCoupon({
          suit_id: this.suitId,
          currency: this.formData.currency,
          coupon_code: this.formData.coupon_code,
          exchange_rate: this.totalExchanged?.exchange_rate,
        });
        this.errors.coupon_code = "";
        this.couponDetail = {
          couponCode: this.formData.coupon_code,
          couponName: res.name,
          discount: res.discount,
          total: res.total_price_after_value,
        };
        if (res.total_price_after_value === 0) {
          this.disablePaymentFlow(true);
        } else {
          this.disablePaymentFlow(false);
        }
      } catch (error) {
        this.couponDetail = {
          couponCode: "",
          couponName: "",
          discount: "",
          total: "",
        };
        this.errors.coupon_code = error.message;
        this.disablePaymentFlow(false);
      } finally {
        this.isLoadingDiscount = false;
      }
    },
    resetInput(inputName) {
      const input = this.$refs[`${inputName}`];
      if (input && input.$children[0]) {
        input.$children[0].reset();
      }
    },
  },

  computed: {
    shareUrl() {
      let currentUrl = window.location.href;
      currentUrl =
        currentUrl.indexOf("?") < 0
          ? currentUrl
          : currentUrl.substring(0, currentUrl.indexOf("?"));
      currentUrl =
        currentUrl +
        `?suit=${this.suitCombo.suit}&shirt=${this.suitCombo.shirt}&tie=${this.suitCombo.tie}&color=${this.suitCombo.color}`;
      return currentUrl;
    },
  },

  async beforeMount() {
    this.userId = this.$route.query.userId;
    this.suitId = this.$route.query.suitId;
    this.checkUrlParams();
    this.loading = true;
    try {
      await this.getSuitMasterData();
      await this.createSuitCombo();
      await this.getCountry();
      await this.getExtraShippingFee();
      const data = await this.getGuestInfo(this.userId);
      if (data) {
        for (const property in data) {
          if (property in this.formData && data[property]) {
            this.formData[property] = data[property];
          }
          if (`address_${property}` in this.formData && data[property]) {
            this.formData[`address_${property}`] = data[property];
          }
          if (property === "email" && data[property]) {
            this.formData["confirm_email"] = data[property];
          }
        }
      }
    } catch (error) {
      alert(error.message);
    }
    const el = this;
    await new Promise((resolve) => {
      setTimeout(function () {
        if (this.isRenderedPaymentInput || !el.suitCombo) {
          el.loading = false;
        }
      }, 1000);
    });
  },
  mounted() {
    const el = this;
    this.$nextTick().then(function () {
      const listScript = document.getElementsByTagName("script");
      const stripeScriptIsExit = Array.from(listScript).some(
        (script) => script.src === "https://js.stripe.com/v3/"
      );
      if (!stripeScriptIsExit) {
        el.loading = true;
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        script.addEventListener("load", () => {
          el.createStripeElm();
        });
      } else {
        el.createStripeElm();
      }
    });
    window.addEventListener("popstate", function ($event) {
      if (el.step === 2) {
        window.location.hash = "#/suit";
      }
    });
  },
  unmounted() {
    this.$refs.btnComplete.removeEventListener("click", () => {
      // no action
    });
    window.removeEventListener("popstate", () => {
      // no action
    });
  },

  watch: {
    fullSuit: function (val) {
      if (!val) return;
      this.getExchangeTotalPrice();
      this.updatetieColor();
    },
    "formData.address_country": {
      deep: true,
      async handler(val) {
        if (val) {
          const response = await this.getState(val);
          this.stateShipping = response;
        }
      },
    },
    "formData.billing_address_country": {
      deep: true,
      async handler(val) {
        if (val) {
          const response = await this.getState(val);
          this.stateBilling = response;
        }
      },
    },
    activeBillingAddress: function () {
      this.resetInput("billingFirstName");
      this.resetInput("billingLastName");
      this.resetInput("billingAddress");
      this.resetInput("billingCountry");
      this.resetInput("billingCity");
      this.resetInput("billingState");
      this.resetInput("billingZipcode");
    },
  },
  beforeDestroy() {
    window.removeEventListener("popstate", function () {
      // do nothing
    });
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  font-family: "Poppins";

  .payment-page {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #304251;

    p {
      margin: 0;
    }

    .wrapper-payment {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      width: 514px;
      margin: 40px 0;
      border-radius: 15px;

      .suit-payment {
        margin: 20px 30px;

        .btn-go-back {
          font-weight: 600;
          font-size: 16px;
          display: flex;
        }

        .suit-payment-title {
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          margin: 30px 0;
        }

        .suit-info {
          display: grid;
          grid-template-columns: 1fr 1fr;

          .left-side {
            display: grid;
            justify-content: center;
            grid-gap: 20px;

            p,
            span {
              text-align: center;
            }

            .selected-suit {
              width: 100%;
              display: flex;
              flex-direction: column;
              grid-gap: 2px;
              justify-self: center;
            }

            .cost {
              width: 100%;

              .cost-row {
                width: 100%;
                display: grid;
                grid-template-columns: 100px auto;

                p {
                  text-align: start;
                }

                &.discount {
                  .text {
                    padding-left: 2px;

                    &.loading-discount {
                      padding-left: 10px;
                    }
                  }
                }

                &.total {
                  margin-top: 5px;
                  border-top: 1px solid #000;
                }

                .text {
                  padding-left: 10px;
                }
              }
            }

            .suit-info-title {
              font-weight: 600;
            }
          }

          .right-side {
            display: grid;
            justify-content: center;

            .full-suit-comp {
              position: relative;
              width: fit-content;

              &__base-image {
                position: relative !important;
              }

              img {
                width: 114px;
                height: 167px;
                position: absolute;
                top: 0;
                left: 0;
              }

              svg {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                mix-blend-mode: multiply;
              }

              .tie-component {
                background-size: contain;
                background-repeat: no-repeat;
                width: 114px;
                height: 167px;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }

        .card-info {
          padding: 30px 0;

          .payment-method {
            display: grid;
            justify-items: end;
          }

          .card-form {
            display: grid;

            .info-row {
              margin-bottom: 30px;

              .info-title {
                font-weight: 600;

                span {
                  font-weight: 400;
                  font-size: 12px;
                }
              }

              .error-message {
                font-size: 12px;
                color: #ff5252;
              }

              .field {
                padding: 0 14px !important;
                border-radius: 19px;
                margin-bottom: 4px;
                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.38);
                height: 36px;

                &.fieldEmpty,
                &.StripeElement--invalid {
                  box-shadow: 0 0 0 2px #ff5252;
                }

                &.disabled-input {
                  background: #f4f6f6;
                  box-shadow: 0 0 0 1px #f4f6f6;
                }
              }

              &.coupon-code {
                display: grid;
                grid-gap: 10px;

                .coupon-box {
                  display: grid;
                  grid-template-columns: 1fr 1fr;

                  .apply-coupon-btn {
                    width: 100px;
                    justify-self: center;
                    border: 1px solid #304251;
                    border-radius: 2px;
                    height: 32px;
                    padding: 4px 15px;
                    color: #fff;
                    background: #304251;

                    &:disabled {
                      opacity: 0.7;
                      cursor: not-allowed;
                    }
                  }
                }

                .discount {
                  padding: 0 20px;
                  background-color: #ca6d4f;
                  width: fit-content;
                  color: #fff;
                }
              }

              &.name {
                display: grid;
                grid-gap: 10px;

                div {
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  grid-gap: 20px;
                }
              }

              &.card-number {
                display: grid;
                grid-template-rows: 30px auto auto;
              }

              &.expDate-Cvc {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 20px;

                .expDate,
                .cvc {
                  display: grid;
                  grid-template-rows: 30px auto auto;
                }
              }

              &.wedding-infomation {
                display: grid;
                grid-gap: 15px;

                .email,
                .name,
                .partyName-phoneNumber {
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  grid-gap: 10px;
                }
              }

              &.shipping {
                display: grid;
                grid-gap: 10px;

                .address-info,
                .address-name {
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  grid-gap: 15px;
                }

                .shipping-fee {
                  padding: 10px;
                }
              }

              &.billing-checkbox {
                display: grid;
                grid-template-columns: max-content auto;
                grid-gap: 10px;
                align-items: center;
                width: fit-content;
                justify-self: center;
                font-size: 17px;
                font-weight: 600;
                color: #304251;

                .text {
                  &::after {
                    content: "Billing address different than shipping address";
                  }
                }
              }
            }

            .btn-complete {
              justify-self: center;
              font-size: 16px;
              font-weight: 500;
              width: 280px;
              height: 47px;
              background: #152940;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
              color: white;
              font-family: "Roboto";
            }
          }

          .currency-payment-box {
            display: flex;

            .currency-cover {
              margin-right: 20px;

              section {
                width: 100%;
              }
            }

            .currency-cover,
            .payment-method-cover {
              .info-title {
                font-weight: 600;
                margin-bottom: 10px;

                span {
                  font-weight: 400;
                  font-size: 12px;
                }
              }
            }

            .hidden {
              visibility: hidden;
            }
          }
        }
      }

      .payment-confirmed {
        margin: 50px 75px 40px 75px;
        text-align: center;
        display: grid;
        grid-gap: 25px;

        .payment-confirmed-title {
          font-size: 24px;
          font-weight: 600;
        }

        .suit-status {
          .suit-status-title {
            font-weight: 600;
          }
        }

        hr {
          margin: 0;
        }

        .btn-done {
          font-family: "Roboto";
          width: 218px;
          height: 39px;
          background-color: #152940;
          margin: 0 auto;
          color: white;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  .nodata {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000038;
    width: 100vw;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-content: center;
  }
}

.text-field {
  border-color: #707070;
  font-family: "Poppins" !important;

  ::placeholder {
    font-weight: 300;
  }
}

@media only screen and (max-width: 576px) {
  .wrapper {
    .payment-page {
      .wrapper-payment {
        box-shadow: unset;
        margin: 0;
        border: unset;
        border-radius: 0;
        width: 100%;

        .payment-confirmed {
          margin: 0 20px auto;
        }

        .card-info {
          .card-form {
            .info-row {
              &.billing-checkbox {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .wrapper {
    .payment-page {
      justify-content: unset;

      .wrapper-payment {
        .suit-payment {
          .suit-info {
            grid-template-columns: 1fr;
            grid-gap: 20px;
          }

          .card-info {
            display: grid;
            grid-gap: 20px;

            .payment-method {
              justify-content: center;
            }

            .card-form {
              .info-row {

                &.expDate-Cvc,
                &.email,
                &.shipping,
                &.name div {
                  grid-template-columns: 1fr;
                }

                &.wedding-infomation {
                  display: grid;
                  grid-gap: 15px;
                }

                &.billing-checkbox {
                  justify-self: flex-start;

                  .text {
                    &::after {
                      content: "Billing different shipping";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.theme--light.v-list-item:hover::before {
  opacity: 0 !important;
}

.billding-address-list {
  .v-list-item {
    min-height: unset !important;
  }

  .v-list-group__header {
    padding: 0px !important;
    justify-content: center;
  }

  .v-list-item__icon {
    display: none;
  }

  .billing-address {
    padding: 0px;
    display: grid;
    grid-gap: 15px;

    &::after {
      display: none;
    }

    .billing-title {
      font-weight: 600;
    }

    .item {
      width: 100%;

      &.name,
      &.country-city,
      &.state-zipcode {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
      }
    }
  }

  .mobile-btn {
    display: none;
  }

  @media only screen and (max-width: 480px) {
    .desktop-btn {
      display: none;
    }

    .mobile-btn {
      display: block;
    }
  }
}
</style>
